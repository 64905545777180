/* Genel Stil */
body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
}

/* Login Container */
.login-container {
    padding-top: 30px;
}

/* Login Kartı */
.login-card {
    border-radius: 10px;
    background: linear-gradient(135deg, #fbfbfb 0%, #f4f3f3 100%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

/* Başlık */
.login-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

/* Form Kontrolleri */
.login-card .form-control {
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
}

.login-card .form-control:focus {
    box-shadow: none;
    border-color: #007bff;
}

/* Şifremi Unuttum */
.forgot-password-link {
    font-size: 14px;
    text-decoration: none;
    color: #007bff;
}

.forgot-password-link:hover {
    text-decoration: underline;
}

/* Butonlar */
.btn-primary {
    background-color: #2b3f96;
    border: none;
}

.btn-primary:hover {
    background-color: #1d2d6c;
}

.btn-secondary {
    background-color: #e9ecef;
    color: #333;
}

.btn-warning {
    background-color: #ff7a00;
    color: #fff;
}

.btn-light {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #333;
}

/* Ayırıcı Çizgi */
hr {
    border-top: 1px solid #ddd;
    margin: 20px 0;
}

/* Alt Bölüm */
.text-center p {
    font-size: 14px;
    color: #666;
}

.text-center .fw-bold {
    font-size: 16px;
    color: #333;
}
.login-image {
     width: 120px;
     height: 120px;
     margin: 0 auto 2px auto;
     display: block;
 }

/* Google Butonu */
.google-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; /* İkon ve yazı arasında boşluk */
    font-size: 14px;
    font-weight: 600;
    color: #333;
    border: 1px solid #ccc;
    padding: 10px;
}

.google-btn:hover {
    background-color: #f1f1f1;
}

/* Google İkonu */
.google-icon {
    color: #DB4437; /* Google Kırmızı Rengi */
    font-size: 18px;
}
/* Alert Kutuları */
.alert {
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
}