.gradient-button {
    background: linear-gradient(45deg, #007bff, #0056b3);
    border: none;
    color: #fff;
    font-weight: bold;
    padding: 0.5rem 1rem;
    transition: background 0.3s ease, transform 0.2s ease;
    width: 100%;
}

.gradient-button:hover {
    background: linear-gradient(45deg, #ff5722, #ff9800);
    transform: scale(1.05);
    color: #fff;
}

.gradient-button:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(255, 87, 34, 0.8);
}
.slick-prev, .slick-next {
    width: 70px;
    height: 70px;
    z-index: 10; /* Slider üzerindeki diğer elemanların üstünde görünmesi için */
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
}

.slick-prev:hover, .slick-next:hover {
    background-color: #0056b3;
}

.slick-prev {
    left: -50px; /* Sol oka mesafe ayarı */
}

.slick-next {
    right: -50px; /* Sağ oka mesafe ayarı */
}
.container, .container-fluid {
    width: 100%;
    padding-right: 5px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.sepete-ekle-button {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0;
    background: yellow;
    background: linear-gradient(45deg, #FFEA00, #e4d201);
    border: none;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.96);
    transition: all 0.3s ease;
}

.sepete-ekle-button:hover {
    background: linear-gradient(45deg, #f1c40f, #d4ac0d); /* Biraz daha koyu ama sarı tonları uyumlu */
    color: #fff;               /* İsterseniz yazı rengini de değiştirebilirsiniz */
}

/* --- Mobil uyumluluk için eklemeler: --- */
@media (max-width: 576px) {
    /* Popüler Ürünler başlığı ve linkinin üst/yan boşluğunu azalt */
    .populer-urunler-header {
        margin-top: 20px !important;
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    /* Ürün başlığını 2 satıra düşür, kart yüksekliği mobilde çok uzamasın */
    .product-title-clamp {
        -webkit-line-clamp: 2 !important; /* 2 satır */
        height: 2.4em !important;         /* line-height (1.2em) * 2 */
    }
}

.mobile-category-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    margin-left: 10px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .mobile-category-toggle {
        display: inline-block;
    }
    .kategori-menu.active {
        max-height: 1000px; /* yeterince büyük bir değer veriyoruz */
        transition: max-height 0.4s ease;
    }
}

@media (max-width: 768px) {
    .kategori-kutusu {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 6px rgba(0,0,0,0.1);
        margin-bottom: 1px;
        margin-top: 10px;
        padding: 20px 10px;
    }

}

@media (max-width: 768px) {
    .mobile-category-toggle {
        display: inline-block;
    }

    .kategori-menu {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.4s ease;
    }

    .kategori-menu.active {
        max-height: 1000px; /* veya daha büyük */
    }
}