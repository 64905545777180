.custom-card {
    border-radius: 10px;
    padding: 20px;
    background-color: #f9f9f9;
}

.profile-picture {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 2px solid #ff9800;
}

.custom-card h3 {
    color: #333;
    font-weight: bold;
}

.custom-card .text-center {
    margin-bottom: 10px;
}

button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
}
