.iletisim {
    font-family: Arial, sans-serif;
    background-color: #fff;
}

.iletisim-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
}

.iletisim-bilgi h2,
.iletisim-form h2 {
    font-weight: 700;
    margin-bottom: 20px;
}

.iletisim-form p {
    font-weight: 600;
    color: black;
    font-size: 15px;
}

.bilgi-listesi {
    list-style: none;
    padding: 0;
    line-height: 4;
    margin-bottom: 30px;
}

.bilgi-listesi li{
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.banka-bilgisi {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: 10px;
}

.iletisim-form {
    background-color: #ffffff;
    padding: 0 30px;
}

.iletisim-gonder {
    background-color: #3d3dc7;
    color: #fff;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.iletisim-gonder:hover {
    background-color: #2929a3;
}
.banka-bilgisi p{
    color: black;
}