/* Benzer Ürünler için özel arrow stilleri */
.slick-prev, .slick-next {
    width: 50px;
    height: 50px;
    border-radius: 50%; /* Yuvarlak buton */
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 10;
    color: #000000;
}

.slick-prev {
    left: -55px; /* Sol ok dışarıda kalmasın */
    color: black;

}

.slick-next {
    right: -55px; /* Sağ ok dışarıda kalmasın */
}

/* Ok ikonları */
.slick-prev::before, .slick-next::before {
    font-size: 35px;
    color: #000000;
    opacity: 1; /* Tam görünür */
}

/* Hover'da daha belirgin olsun */
.slick-prev:hover, .slick-next:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

/* Mobilde biraz daha küçük görünsün */
@media (max-width: 768px) {
    .slick-prev, .slick-next {
        width: 40px;
        height: 40px;
    }

    .slick-prev {
        left: -35px;
    }

    .slick-next {
        right: -35px;
    }

    .slick-prev::before, .slick-next::before {
        font-size: 20px;
    }
}
