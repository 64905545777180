@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.button-custom {
    font-weight: bold;
    letter-spacing: 0.5px;
    transition: all 0.3s ease;
}


@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1420px;
    }
}


.button-custom:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.banner-background {
    position: relative;
    background-image: url('assets/arkaplan.png'); /* Arka plan resminizi buraya ekleyin */
    background-size: cover;
    background-position: center;
    height: 100px; /* Banner yüksekliğini isteğinize göre ayarlayın */
}



.banner-dark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.31); /* Karartma efektinin opaklığını buradan ayarlayın */
    display: flex;
    align-items: center; /* İçeriği dikey olarak ortalar */
    justify-content: center; /* İçeriği yatay olarak ortalar */
}

.banner-content h1 {
    color: white;
    text-align: center;
}

/* Tüm kartların aynı yükseklikte olmasını sağlayacak CSS */
.custom-col {
    display: flex;
    flex: 1;
}

.custom-card {
    width: 100%; /* Kartın container'ını doldurmasını sağlar */
    display: flex;
    flex-direction: column; /* İçerikleri dikey olarak sıralar */
}

.custom-card-body {
    flex-grow: 1; /* Kart içeriğinin mevcut alanı doldurmasını sağlar */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Başlık ve açıklamayı yukarı ve aşağıya yaslar */
}



/* Menü Linkleri */
.topbar-menu a {
    color: #333;
    font-size: 16px;
    font-weight: normal;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 3px; /* İkon ve metin arası boşluk */
    transition: color 0.3s ease;
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;

}

.topbar-menu a:hover {
    color: #0c82f8; /* Hover rengini değiştir */
}




/* Genel Ayarlar */
.topbar {
    background-color: #f2f2f2;
    padding: 4px 0; /* Yukarıdan ve aşağıdan boşluk */
    border-bottom: 1px solid #ccc;
    font-family: 'Arial', sans-serif;
}

/* Sol Bölüm - İletişim */
.topbar-contact {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: #000000;
    font-family: "Poppins", sans-serif;

}

/* Sosyal Medya ve Menü */
.topbar-socials, .topbar-menu {
    display: flex;
    align-items: center;
    gap: 2px; /* İkonlar ve öğeler arasında boşluk */

}

.topbar-socials a {
    color: #000000;
    font-size: 18px;
    text-decoration: none;
    transition: color 0.3s ease;
    padding: 0;
    font-width: 400;
    margin: 0 5px;
    font-family: "Poppins", sans-serif;

}

.topbar-socials a:hover, .topbar-menu a:hover {
    color: #007bff; /* Hover rengini değiştir */
}

/* Bölücü (|) */
.divider {
    color: #333;
    margin: 0 10px; /* Sağ ve sol boşluk */
    font-weight: bold;
    font-size: 14px;
}

/* Butonlar */
.topbar-button {
    font-size: 14px;
    font-weight: bold;
    border-radius: 14px;
    padding: 2px 10px;
    margin-left: 10px;
    background-color: white;
    color: #333;
    border: 1px solid #333;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-family: "Poppins", sans-serif;

}

.topbar-login:hover, .topbar-signup:hover {
    background-color: #007bff;
    color: white;
}

/* Dropdown Buton */
.dropdown-toggle-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between; /* İçerik ve ikon arasında boşluk oluşturur */
    width: 100%; /* Buton genişliğini kapsar */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}
.dropdown-toggle-button:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Dropdown İkon */
.dropdown-icon {
    font-size: 14px;
    margin-left: 10px; /* Butonun içeriği ile ikon arasındaki boşluk */
    transition: transform 0.3s ease;
}

.custom-dropdown.show .dropdown-icon {
    transform: rotate(180deg); /* Menü açıldığında ikon yukarı döner */
}

/* Dropdown Menü Genel Ayarları */
.custom-dropdown .dropdown-menu {
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 10px 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: slideDown 0.3s ease-in-out;
}

/* Dropdown Öğeleri */
.custom-dropdown-item {
    padding: 10px 20px;
    font-size: 14px;
    color: #333;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-dropdown-item:hover {
    background-color: #f1f1f1;
    color: #FA8C17;
}

/* Yukarıdan Aşağıya Animasyon */
@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
/* Varsayılan Dropdown İkonunu Kaldır */
.custom-dropdown .dropdown-toggle::after {
    display: none; /* Varsayılan Bootstrap ikonunu gizler */
}
.menu-icon {
    font-size: 18px; /* Menü simgesi boyutu */
    margin-right: 1px; /* Simge ile metin arasında boşluk */
}

.spinner {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #333;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #333 transparent transparent transparent;
}
@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.button-icon{
    font-size: 13px;
    color: #FA8C17;
    margin-bottom: 3px;
    margin-right: 4px;
}



.sidebar {
    width: 25%;
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 8px;
}

.kategori-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.kategori-item {
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    cursor: pointer;
}

.kategori-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    cursor: pointer;

}

.kategori-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.kategori-title {
    flex: 1;
    font-weight: bold;
    color: #333;
    font-size: 16px;
}

.kategori-toggle {
    font-size: 25px;
    font-weight: bold;

}

.alt-kategori-list {
    list-style: none;
    padding: 0 15px;
    margin: 10px 0;
    color: black;
}

.alt-kategori-item {
    margin: 5px 10px;
    cursor: pointer;
    color: #000000;
    transition: color 0.3s ease;
    border-left: 1px solid #007bff;

    padding: 3px 6px;

}

.alt-kategori-item:hover {
    color: #474a4e;
    text-decoration: underline;
}

.urunler-content {
    flex: 1;
}

.urunler-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.urun-card {
    width: 200px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
}

.urun-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.urun-image {
    width: 100%;
    height: 150px;
    object-fit: contain;
    margin-bottom: 10px;
}
.selected-category {
    background-color: #007bff !important;
    color: white !important;
}

.selected-category:hover {
    color: white !important;
}
footer li{
    margin: 15px 0;
    font-size: 17px;
}

.discount-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background: #d62923;
    color: white;
    padding: 5px 8px;
    border-radius: 5px;
    font-size: 0.85rem;
    font-weight: bold;
    z-index: 2;
}

.price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.old-price {
    font-size: 16px;
    font-weight: 500;
    color: #888;
    text-decoration: line-through;
}

.new-price {
    font-size: 18px;
    font-weight: bold;
    color: #d9534f;
}

.product-image-gallery img {
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 5px;
}

.product-discount {
    position: absolute;
    top: 10px;
    left: 10px;
    background: #28a745;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
}

/* GENEL RESİM STİLİ */
.slider-img {
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

/* SLIDER GÖRSELİ */
.slider-main {
    height: 470px;
}

@media (max-width: 1400px) {
    .slider-main {
        height: 390px;
    }
}

@media (max-width: 1320px) {
    .slider-main {
        height: 370px;
    }
}

@media (max-width: 1200px) {
    .slider-main {
        height: 310px;
    }
}

@media (max-width: 992px) {
    .slider-main {
        height: 220px;
    }
}

@media (max-width: 768px) {
    .slider-main {
        height: auto;
        aspect-ratio: 1 / 1;
    }
}

/* SAĞ GÖRSELLER */
.side-img {
    height: 230px;
}

@media (max-width: 1400px) {
    .side-img {
        height: 190px;
    }
}

@media (max-width: 1320px) {
    .side-img {
        height: 180px;
    }
}

@media (max-width: 1200px) {
    .side-img {
        height: 150px;
    }
}


@media (max-width: 992px) {
    .side-img {
        height: 105px;
    }
}

@media (max-width: 768px) {
    .side-img {
        height: auto;
        aspect-ratio: 1 / 1;
    }
}
